import React from 'react';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <div>
    <h1>Error 404. Page not found!</h1>
    <Link to="/">Go home.</Link>
  </div>
);

export default NotFoundPage;
'';
